*{
    color: #fff;
    font-family: 'Open Sans', sans-serif;;
    padding: 0;
    margin: 0;
}

.title{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    font-size: 4rem;
    font-weight: 700;
    color: black;
    margin: 2rem;
    text-decoration: underline;
}

.intro{
    background-color: #1e1e1e;
    padding: 0 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 80vh;
}

.intro img{ 
    height: 69%;
    border-radius: 10%;
    margin-right: 20px;
    box-shadow: 10px 10px 0px 0px #ffc60c;
}

.intro h1{
    margin: 0;
    font-size: 3rem;
    font-weight: 700;
}

.intro h2{
    margin: 0;
    font-size: 3rem;
    font-weight: 900;
    color: #ffc60c;
}
.intro h3{
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
}

.intro .buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.intro .buttons button{
    background-color: #ffc60c;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    color: #1e1e1e;
}

.intro .buttons button:hover{
    background-color: #1e1e1e;
    color: #ffc60c;
}

.socials{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.about{
    background-color: #ffc60c;
    padding: 25px 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about h1{
    font-size: 4rem;
    font-weight: 700;
    color: black;
    margin: 20px 0;
    text-decoration: underline;
}

.about p{
    margin: 0;
    font-size: 1.5rem;
    font-weight: 700;
    max-width: 1000px;
    color: #ffc60c;
    background-color: #1e1e1e;
    padding: 60px;
    border-radius: 35px;
    max-width: 750px;
}

/* make if mobil frendly */
@media screen and (max-width: 800px){
    svg{
        width: 100%;
        height: 80px;
    }
    .intro{
        flex-direction: column;
        height: 80vh;
    }
    .intro img{
        height: 60%;
        margin: 0;
    }
    .intro h1{
        font-size: 2rem;
    }
    .intro h2{
        font-size: 2rem;
    }
    .intro h3{
        font-size: 1rem;
    }
    .aboute{
        padding: 25px 15px;
    }
    .aboute h1{
        font-size: 2.5rem;
    }   
    .aboute p{
        padding: 30px;
        font-size: medium;
    }
    .title{
        font-size: 2.5rem;
    }

}